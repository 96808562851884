import { Table } from "antd";

import React, { useEffect } from "react";

const TableForDesktop = (props: any) => {
  const { columns, dataSource: data, setCurrentPageNo, currentPageNo } = props;

  useEffect(() => {
    console.log("reloading form submission page ---> ");
  }, [currentPageNo]);

  return (
    <>
      <div
        style={{
          width: "100%",
          marginBottom: "20px",
        }}
      ></div>
      {data && (
        <Table
          showHeader={true}
          tableLayout="auto"
          columns={columns}
          dataSource={data}
          bordered={true}
          pagination={{
            position: ["bottomCenter"],
            defaultPageSize: 10,
            current: currentPageNo,
          }}
          size="large"
          onChange={(e) => {
            setCurrentPageNo(e.current);
          }}
        />
      )}
    </>
  );
};

export default TableForDesktop;
