import React, { useEffect, useState } from "react";

import { Table } from "antd";
import { Link } from "react-router-dom";

const FormIndividualStats = (props: any) => {
  const [dataSource, setDataSource] = useState();

  const formUuid = props.formUuid;

  useEffect(() => {
    if (props.metadata) {
      const dataSourceToSet = props.metadata;
      console.log("datS");
      setDataSource(dataSourceToSet);
    }
  }, [props]);

  const columns = [
    {
      title: "Recent Visitors",
      dataIndex: "ip",
      key: "ip",
    },
    {
      title: "Date",
      dataIndex: "created",
      key: "created",
      render: (created: any) => (
        <>
          {" "}
          {new Date(created).toLocaleDateString("en-gb", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
          {", "}
          {
            Intl.DateTimeFormat("en", {
              hour: "numeric",
              minute: "numeric",
            }).format(new Date(created)) // 2 PM
          }
        </>
      ),
    },
    {
      title: "Response",
      dataIndex: "id",
      key: "id",
      render: (id: any, record: any) => (
        <Link
          to={`/forms/${formUuid}/responses/${record.id}`}
          target="_blank"
          style={{ padding: "0px", margin: "0px" }}
        >
          View Responses
        </Link>
      ),
    },
    {
      title: "Location",
      dataIndex: "city",
      key: "city",
      render: (city: any, record: any) => (
        <>
          {city}
          {record.country && record.country !== "-" && `, ${record.country}`}
        </>
      ),
    },
    {
      title: "Browser",
      dataIndex: "browser_info",
      key: "browser_info",
    },
  ];

  const columnsForMobile = [
    {
      title: "Recent Visitors",
      dataIndex: "ip",
      key: "ip",
    },

    {
      title: "Response",
      dataIndex: "id",
      key: "id",
      render: (id: any, record: any) => (
        <Link
          to={`/forms/${formUuid}/responses/${record.id}`}
          target="_blank"
          style={{ padding: "0px", margin: "0px" }}
        >
          View Responses
        </Link>
      ),
    },
  ];

  return (
    <Table
      dataSource={dataSource}
      columns={window.innerWidth > 500 ? columns : columnsForMobile}
      pagination={{
        position: ["bottomCenter"],
        defaultPageSize: 5,
      }}
    />
  );
};

export default FormIndividualStats;
