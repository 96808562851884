import { Table } from "antd";

import React from "react";

const TableForDesktop = (props: any) => {
  const { columns, dataSource: data, setCurrentPageNo, currentPageNo } = props;

  console.log("Data Source", data);

  console.log("Columns", columns);

  return (
    <>
      <div
        style={{
          width: "100%",
          marginBottom: "20px",
        }}
      ></div>
      {data && (
        <Table
          showHeader={true}
          tableLayout="auto"
          columns={columns}
          dataSource={data}
          bordered={true}
          pagination={{
            position: ["bottomCenter"],
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30"],
            current: currentPageNo,
          }}
          size="large"
          onChange={(e) => {
            setCurrentPageNo(e.current);
          }}
          // sticky={true}
          // scroll={window.innerWidth <= 1024 && { x: "95vw", y: "100vh" }}
        />
      )}
    </>
  );
};

export default TableForDesktop;
