import React, { useState } from "react";

import { Form, Input, message, Spin, Button } from "antd";

import axios from "axios";

import { useParams } from "react-router-dom";

import RandomInput from "./RandomInput";

const FileUpload = (props: any) => {
  const [selectedFile, setSelectedFile] = useState<any>(null);

  const [fileExtenstion, setFileExtenstion] = useState<any>(null);

  const [fileSize, setFileSize] = useState<null | number>(null);

  const [fileType, setFileType] = useState<null | string>(null);

  const {
    plugin_data,
    rules,
    setIsFileUploading,
    mode,
    extra,
    uploadedFileKey,
    setUploadedFileKey,
    fileName,
    setFileName,
  } = props;

  const { name, required } = plugin_data;

  const [loading, setLoading] = useState(false);

  const params = useParams<any>();

  const { uuid } = params;

  const onFileChange = async (e: any) => {
    console.log("file details --->", e.target.files[0]);

    if (e.target.files.length > 0) {
      if (
        e.target.files[0].type.match("image") ||
        e.target.files[0].type.match("pdf") ||
        e.target.files[0].type.match(
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) ||
        e.target.files[0].type.match(
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) ||
        e.target.files[0].type.match(
          "application/vnd.oasis.opendocument.spreadsheet"
        )
      ) {
        const fileSize = e.target.files[0].size;

        if (fileSize > 5000000) {
          message.error("Max Image size 5MB allowed.");
        } else {
          setSelectedFile(e.target.files[0]);
          onFileUpload(e.target.files[0]);
        }
      } else {
        message.error("This file type is not supported.");
      }
    } else {
      message.error("Please select a file to upload");
    }
  };

  const attachHeaders = async (data: any) => {
    const formData = new FormData();

    Object.entries(data).forEach((dataItem: any) => {
      formData.append(dataItem[0], dataItem[1]);
    });

    return formData;
  };

  const onFileUpload = async (file: any) => {
    const selectedFile = file;
    if (selectedFile) {
      const file = selectedFile.name !== "" ? selectedFile : "  ";
      const fileName = selectedFile.name !== "" ? selectedFile.name : "  ";

      const extenstion = selectedFile.name
        .substr(selectedFile.name.lastIndexOf(".") + 1)
        .split(".")[0];

      setFileExtenstion(extenstion);

      setFileName(fileName);

      setFileSize(selectedFile.size);

      setFileType(selectedFile.type);

      const keys = await getAWSTokenForLogoUpload(extenstion, fileName);

      const data = await keys.fields;

      const key = data.key;

      const formData: FormData = await attachHeaders(data);

      formData.append("file", file);

      if (formData) {
        const result = await uploadFileToAWS(keys.url, await formData, key);

        console.log("Result from AWS -----> ", result);

        setUploadedFileKey(result);
      }
    } else {
      message.error("No image selected.");
    }
  };

  const getAWSTokenForLogoUpload = async (
    extenstion: string,
    fileName: string
  ) => {
    setLoading(true);
    setIsFileUploading(true);

    let result;

    try {
      const userType = localStorage.getItem("tempAccessToken")
        ? "anon"
        : "user";
      result = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/base/token/?file_extension=${extenstion}&form_uuid=${uuid}&file_name=${fileName}`,
        {
          headers: {
            Authorization:
              userType === "anon" &&
              `Bearer ${localStorage.getItem("tempAccessToken")}`,
          },
        }
      );
    } catch (err) {
      console.log("errr", err);
      message.error("Logo Upload failed.");
      setLoading(false);
      setIsFileUploading(false);
    }

    console.log("result in aws token --> ", result?.data);
    return result?.data?.data;
  };

  const uploadFileToAWS = async (url: string, formData: any, key: string) => {
    var instance = axios.create();
    delete instance.defaults.headers.common["Authorization"];

    return instance
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((response) => {
        message.info("File upload successful.");
        console.log("after file upload", response.data);
        setLoading(false);
        setIsFileUploading(false);
        return key;
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setIsFileUploading(false);
      });
  };

  const handleFileDelete = async (awsKey: string) => {
    try {
      const userType = localStorage.getItem("tempAccessToken")
        ? "anon"
        : "user";
      const deleteResult = await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/api/base/token/?key=${awsKey}`,
        {
          headers: {
            Authorization:
              userType === "anon" &&
              `Bearer ${localStorage.getItem("tempAccessToken")}`,
          },
        }
      );
      console.log(`File delete Result`, deleteResult);
      message.success("File deleted successfully");
      setUploadedFileKey(null);
    } catch (err) {
      console.log("errr", err);
      message.error("File delete failed");
    }
  };

  return (
    <>
      <Form.Item
        name={name}
        required={required}
        rules={rules}
        style={{ margin: "0px", padding: "0px" }}
        extra={extra}
      >
        <RandomInput
          uploadedFileKey={uploadedFileKey}
          fileExtenstion={fileExtenstion}
          fileName={fileName}
          fileType={fileType}
          fileSize={fileSize}
          // onChange={(e: any) => setUploadedFileKey(e)}
        />
      </Form.Item>
      {uploadedFileKey ? (
        <>
          <p style={{ wordBreak: "break-word" }}>{fileName}</p>
          <Button onClick={() => handleFileDelete(uploadedFileKey)}>
            Remove
          </Button>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "18px",
            }}
          >
            <Input
              type="file"
              onChange={(e) => onFileChange(e)}
              style={{ width: "220px", marginRight: 5 }}
              disabled={
                (mode === "preview" && true) || uploadedFileKey || loading
              }
            ></Input>

            {loading ? <Spin /> : <></>}
          </div>
          <p style={{ color: "red", fontSize: "10px" }}>
            Max file size allowed is 5Mb. <br />
            Supported file types include - images, pdf, docs and spreadsheets.
          </p>
        </>
      )}
    </>
  );
};

export default FileUpload;
