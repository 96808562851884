import React, { useState, useEffect } from "react";

import {
  Button,
  Card,
  Divider,
  Layout,
  Typography,
  Image,
  Spin,
  DatePicker,
  TimePicker,
} from "antd";
import axios from "axios";
import MDEditor from "@uiw/react-md-editor";
import moment from "moment";

import { FALLBACK_IMAGE } from "../../../shared/constants/constants";
import FileUploadTypeResponse from "../formBuilderPage/FileUploadTypeResponse";
import ChoiceResponse from "../formBuilderPage/ChoiceResponse";
import ResponseMetadata from "../formBuilderPage/ResponseMetadata";
import { submitConsent } from "../../../auth/core/services/consentAPI";

import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";

const { Title } = Typography;

const SingleResponse = (props: any) => {
  const {
    modalData: submissionData,
    currentIndex,
    setCurrentIndex,
    changeCurrentIndex,
    submissionsCount,
  } = props;
  const [loading, setLoading] = useState(false);

  console.log("-------------> ", currentIndex, submissionsCount);

  const [metadata, setMetadata] = useState();

  const modalData = submissionData.data;

  useEffect(() => {
    setMetadata(submissionData.metadata);
  }, [submissionData]);

  const handleIndexIncrement = () => {
    // if (currentIndex! >= submissionsCount) {
    changeCurrentIndex(currentIndex + 1);
    // }
  };

  const handleIndexDecrement = () => {
    // if (currentIndex === 0) {
    changeCurrentIndex(currentIndex - 1);
    // }
  };

  useEffect(() => {
    const getS3FilePath = async (media_key: string, field: any) => {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/base/get-s3-url/?key=${media_key}`
        );
        console.log(`result from AWS`, result);
        if (field.questionImageKey) {
          field.questionImageUrl = result.data.url;
        } else {
          field.imageUrl = result.data.url;
        }
      } catch (error) {
        console.log("errors could not be get", error);
      }
    };

    setLoading(true);
    const promises: any = [];
    modalData.forEach((element: any) => {
      if (element.element_metadata.hasOwnProperty("questionImageKey")) {
        promises.push(
          getS3FilePath(element.element_metadata.questionImageKey, element)
        );
      }
      if (element.element_metadata.elementType === "Radio") {
        if (element.response.imageKey) {
          promises.push(
            getS3FilePath(element.response.imageKey, element.response)
          );
        }
      }
      if (element.element_metadata.elementType === "Checkbox") {
        element.response.forEach((choice: any) => {
          if (choice.imageKey) {
            promises.push(getS3FilePath(choice.imageKey, choice));
          }
        });
      }
    });

    const allPromises = Promise.all(promises).then((values) => {
      console.log(`values`, values);
      setLoading(false);
    });
  }, []);

  const elementToReturn = (dataItem: any) => {
    let dataToReturn = null;
    if (dataItem) {
      if (dataItem.element_metadata.elementType === "Long Text") {
        return <MDEditor.Markdown source={dataItem.response} />;
      }
      if (
        ["Dropdown", "Checkbox", "Radio"].includes(
          dataItem.element_metadata.elementType
        )
      ) {
        if (Array.isArray(dataItem.response)) {
          return dataItem.response.map((choice: any) => {
            return (
              <ChoiceResponse
                choice={choice}
                elementType={dataItem.element_metadata.elementType}
              />
            );
          });
        } else {
          return (
            <ChoiceResponse
              choice={dataItem.response}
              elementType={dataItem.element_metadata.elementType}
            />
          );
        }
      }

      console.log("dataItem here --->", dataItem);
      // boolean
      if (dataItem.element_metadata.elementType === "Switch") {
        dataToReturn = dataItem.response;
      }
      // date
      else if (dataItem.element_metadata.elementType === "DatePicker") {
        dataToReturn = (
          <DatePicker
            value={moment(dataItem.response)}
            format={dataItem.element_metadata.dateFormat}
            disabled
          />
        );
      }
      // time
      else if (dataItem.element_metadata.elementType === "TimePicker") {
        dataToReturn = (
          <TimePicker
            value={moment(dataItem.response, "HH:mm:ss")}
            format={"HH:mm"}
            disabled
          />
        );
      }
      // url or email
      else if (
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(
          dataItem.response
        )
      ) {
        // email
        if (
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
            dataItem.response
          )
        ) {
          dataToReturn = <p>{dataItem.response.toString()}</p>;
        }
        // url
        else {
          dataToReturn = (
            <a
              href={dataItem.response.toString()}
              target="_blank"
              rel="noreferrer"
              key={dataItem.response.toString()}
            >
              {dataItem.response.toString()}
            </a>
          );
        }
      }
      // upload
      else if (
        dataItem.element_metadata.elementType === "Upload" ||
        dataItem.element_metadata.elementType === "Video"
      ) {
        return (dataToReturn = (
          <div style={{ height: "100%", width: "100%", overflow: "none" }}>
            <FileUploadTypeResponse fileData={dataItem.response} />
          </div>
        ));
      }
      // pretty much everything else
      else {
        dataToReturn = dataItem.response.toString();
      }
      return dataToReturn;
    }

    if (dataToReturn) {
      return dataToReturn;
    }
  };

  return (
    <Spin spinning={loading}>
      <Layout
        style={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          marginTop: "30px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              marginTop: "20px",
              // width: "500px",
              width: "100%",
              maxWidth: "1000px",
              textAlign: "left",
              padding: "20px",
              height: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "20px",
                height: "60px",
              }}
            >
              <Button
                type="link"
                onClick={() => handleIndexDecrement()}
                disabled={currentIndex === 0}
                style={{ height: "100%" }}
              >
                <LeftCircleOutlined style={{ fontSize: "30px" }} />{" "}
              </Button>
              {submissionData.metadata &&
              submissionData.metadata.form_submitter_email ? (
                <Button type="link" style={{ height: "100%" }}>
                  <b style={{ fontSize: "16px" }}>
                    {submissionData.metadata.form_submitter_email}
                  </b>
                </Button>
              ) : (
                <Button type="link" style={{ height: "100%" }}>
                  <b style={{ fontSize: "16px" }}>
                    Submission ID {submissionData.id}
                  </b>
                </Button>
              )}
              <Button
                type="link"
                onClick={() => handleIndexIncrement()}
                disabled={currentIndex >= submissionsCount - 1}
                style={{ height: "100%" }}
              >
                <RightCircleOutlined style={{ fontSize: "30px" }} />
              </Button>
            </div>
            {modalData && modalData.length > 0 ? (
              <>
                <div style={{ textAlign: "center" }}>
                  <ResponseMetadata
                    metadata={metadata}
                    submissionData={submissionData}
                  />
                </div>
                {modalData.map((dataItem: any) => (
                  <div key={dataItem}>
                    <Divider />
                    <Title level={3}>{dataItem.label}</Title>
                    {Boolean(dataItem.element_metadata.questionImageKey) && (
                      <Image
                        src={dataItem.imageUrl}
                        height={dataItem.element_metadata.questionImageSize}
                        width={dataItem.element_metadata.questionImageSize}
                        alt="Question Image"
                        fallback={FALLBACK_IMAGE}
                      />
                    )}
                    <Title level={5}>
                      {" "}
                      {elementToReturn(dataItem) !== null &&
                        elementToReturn(dataItem)}
                    </Title>
                  </div>
                ))}
              </>
            ) : (
              <Card>Nothing to show here</Card>
            )}
          </div>
        </div>
      </Layout>
    </Spin>
  );
};

export default SingleResponse;
