import React from "react";

import { Col, Row, Space, Button } from "antd";

import TableForDesktop from "./TableForDesktop";

import { EyeOutlined } from "@ant-design/icons";

const FormSubmissionLists = (props: any) => {
  const {
    formSubmissionData,
    handleClickViewResponse,
    setCurrentPageNo,
    currentPageNo,
  } = props;

  console.log("formSubmissionData in lists", formSubmissionData);

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      ellipsis: true,
      render: (id: any, record: any) => <>{id}</>,
    },
    {
      title: "Submitter",
      dataIndex: "id",
      ellipsis: true,
      render: (id: any, record: any) => (
        <>
          {record.metadata && record.metadata.form_submitter_email ? (
            <p> {record.metadata.form_submitter_email}</p>
          ) : (
            <p> Submission ID {id} </p>
          )}
        </>
      ),
    },
    {
      title: "Date",
      dataIndex: "created",
      render: (created: any) => (
        <p>
          {" "}
          {new Date(created).toLocaleDateString("en-gb", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
          {", "}
          {
            Intl.DateTimeFormat("en", {
              hour: "numeric",
              minute: "numeric",
            }).format(new Date(created)) // 2 PM
          }
        </p>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => {
              handleClickViewResponse(record);
            }}
          >
            View Response
          </Button>
        </Space>
      ),
    },
  ];

  const columnsForMobile = [
    {
      title: "Submitter",
      dataIndex: "id",
      ellipsis: true,
      render: (id: any, record: any) => (
        <>
          {record.metadata && record.metadata.form_submitter_email ? (
            <p> {record.metadata.form_submitter_email}</p>
          ) : (
            <p> Submission ID {id} </p>
          )}
        </>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => handleClickViewResponse(record)}
          >
            <EyeOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Row>
        <Col
          span={24}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {formSubmissionData && formSubmissionData.length !== 0 && (
            <TableForDesktop
              columns={window.innerWidth < 769 ? columnsForMobile : columns}
              dataSource={formSubmissionData}
              currentPageNo={currentPageNo}
              setCurrentPageNo={setCurrentPageNo}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default FormSubmissionLists;
