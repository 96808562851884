import React from "react";

import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";

function BreadcrumbNavigator() {
  return (
    <Breadcrumb className="ct_forms_breadcrumb">
      <Breadcrumb.Item>
        <Link to={`/forms/all`}>Forms</Link>
      </Breadcrumb.Item>
    </Breadcrumb>
  );
}

export default BreadcrumbNavigator;
