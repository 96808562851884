import { Table } from "antd";
import React from "react";

const TableForDesktop = (props: any) => {
  const { columns, dataSource: data, loading } = props;

  return (
    <>
      <div style={{ maxWidth: "80vw" }}>
        <Table
          showHeader={true}
          tableLayout="fixed"
          columns={columns}
          dataSource={data}
          bordered={true}
          pagination={false}
          loading={loading}
          size="middle" // changed from large to middle
          // sticky={true}
        />
      </div>
    </>
  );
};

export default TableForDesktop;
