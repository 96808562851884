import React from "react";

import { Col, Row, Button } from "antd";

import TableForDesktop from "./TableForDesktop";

const ResponsesList = (props: any) => {
  const {
    formSubmissionData,
    handleClickViewResponse,
    setCurrentPageNo,
    currentPageNo,
    selectedId,
  } = props;

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      ellipsis: true,
      render: (id: any, record: any) => <>{id}</>,
    },
    {
      title: "Submitter",
      dataIndex: "id",
      ellipsis: true,
      width: "200px",
      render: (id: any, record: any, index: any) => (
        <div
          style={{
            textAlign: "left",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          {record.metadata && record.metadata.form_submitter_email ? (
            <Button
              type="link"
              onClick={() => {
                handleClickViewResponse(
                  record,
                  (currentPageNo - 1) * 10 + index
                );
              }}
              style={{
                textOverflow: "ellipsis",
                wordWrap: "normal",
                width: "200px",
                overflow: "none",
                textAlign: "left",
                alignContent: "left",
                left: "0px",
                margin: "0px",
                padding: "0px",
              }}
            >
              {console.log("each record has data ----->", record)}
              <p
                style={{
                  color: selectedId === id ? "" : "black",
                  fontSize: selectedId === id ? "16px" : "14px",
                  fontWeight: selectedId === id ? "bold" : "normal",
                  textOverflow: "ellipsis",
                  width: "100%",
                  overflow: "clip",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                {" "}
                <u> {record.metadata.form_submitter_email}</u>
              </p>
            </Button>
          ) : (
            <Button
              type="link"
              onClick={() => {
                handleClickViewResponse(
                  record,
                  (currentPageNo - 1) * 10 + index
                );
              }}
              style={{
                textOverflow: "ellipsis",
                wordWrap: "normal",
                width: "200px",
                overflow: "none",
                textAlign: "left",
                alignContent: "left",
                left: "0px",
                margin: "0px",
                padding: "0px",
              }}
            >
              <p
                style={{
                  color: selectedId === id ? "" : "black",
                  fontSize: selectedId === id ? "16px" : "14px",
                  fontWeight: selectedId === id ? "bold" : "normal",
                  textOverflow: "ellipsis",
                  width: "100%",
                  overflow: "clip",
                }}
              >
                {" "}
                <u>Submission ID {id} </u>
              </p>
            </Button>
          )}
        </div>
      ),
    },
  ];

  return (
    <div>
      <Row>
        <Col
          span={24}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {formSubmissionData && formSubmissionData.length !== 0 && (
            <TableForDesktop
              columns={columns}
              dataSource={formSubmissionData}
              currentPageNo={currentPageNo}
              setCurrentPageNo={setCurrentPageNo}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ResponsesList;
