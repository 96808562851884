import React, { useEffect, useState } from "react";

import Layout, { Content } from "antd/lib/layout/layout";

import { Route, Switch, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import ForgotPassword from "../../../auth/containers/forgotPassword/ForgotPassword";
import Login from "../../../auth/containers/login/Login";
import Register from "../../../auth/containers/register/Register";
import ResetPassword from "../../../auth/containers/forgotPassword/ResetPassword";

import { getCurrentSessionTokens } from "../../../auth/core/services/session";

import "../../../App.less";

import Dashboard from "../../containers/dashboard/Dashboard";

import PageNotFound from "../../containers/pagenotfound/PageNotFound";

import Sidebar from "../sidebar/Sidebar";
import Navbar from "../navbar/Navbar";

import PrivateRoute from "./PrivateRoute";
import Profile from "../../containers/profile/Profile";

import SidebarMobile from "../sidebar/SidebarMobile";
import NavbarMobile from "../navbar/NavbarMobile";
import ChangePassword from "../../../auth/containers/changePassword/ChangePassword";
import Forms from "../../containers/forms/Forms";
import SettingsPage from "../../../auth/containers/settings/SettingsPage";
import EditFormPage from "../../containers/forms/FormVisibility";
import FormBuilder from "../../containers/formBuilderPage/FormBuilder";
import FormSubmit from "../../containers/formSubmission/FormSubmit";
import EmailVerificationPage from "../../../auth/containers/emailVerification/EmailVerificationPage";
import DownloadMedia from "../../containers/media/DownloadMedia";
import FormSubmitIframe from "../../containers/formSubmissionIFrame/FormSubmitIframe";

const Routes = (props: any) => {
  const { pathname } = useLocation();

  const [collapsed, setCollapsed] = useState(false);

  const [width, setWidth] = useState<number | undefined>();
  const [height, setHeight] = useState<number | undefined>();
  const [sidebarMargin, setSidebarMargin] = useState<any>("0px");

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const updateSidebarMargin = () => {
    if (isAuthenticated) {
      if (width && width < 1024) {
        setSidebarMargin("0px");
      } else if (collapsed) {
        setSidebarMargin("80px");
      } else if (hideSidebarAndNavbar) {
        setSidebarMargin("0px");
        setCollapsed(true);
      } else {
        setSidebarMargin("200px");
      }
    } else {
      setSidebarMargin("0px");
    }
  };

  useEffect(() => {
    const { innerWidth: width, innerHeight: height } = window;

    setWidth(width);
    setHeight(height);
    console.log("window --->", width, height);

    updateSidebarMargin();
  });

  useEffect(() => {
    if (width && width > 1024) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
  }, []);

  const { accessToken } = getCurrentSessionTokens();

  const { isAuthenticated } = props.state.auth;

  const hideSidebarAndNavbar = pathname.includes("/form-submit/");

  return (
    <>
      <div>
        {accessToken && width && width >= 1024 && !hideSidebarAndNavbar && (
          <Sidebar
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            toggleCollapsed={toggleCollapsed}
          />
        )}
        {accessToken && width && width < 1024 && !hideSidebarAndNavbar && (
          <SidebarMobile
            collapsed={collapsed}
            toggleCollapsed={toggleCollapsed}
          />
        )}
      </div>

      <div style={{ flex: 1 }}>
        <Layout
          style={{
            marginLeft: hideSidebarAndNavbar ? "0px" : sidebarMargin,
            backgroundColor: "#f0f2f5",
          }}
        >
          {accessToken && width && width >= 1024 && !hideSidebarAndNavbar && (
            <Navbar />
          )}
          {accessToken && width && width < 1024 && !hideSidebarAndNavbar && (
            <NavbarMobile />
          )}

          <Content>
            <div className="App">
              <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/register" component={Register} />{" "}
                <Route
                  exact
                  path="/forgot-password"
                  component={ForgotPassword}
                />
                <Route exact path="/reset" component={ResetPassword} />
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute exact path="/user/profile" component={Profile} />
                <PrivateRoute
                  exact
                  path="/user/account"
                  component={ChangePassword}
                />
                <PrivateRoute exact path="/forms/all" component={Forms} />
                <PrivateRoute
                  exact
                  path="/form/:uuid"
                  component={EditFormPage}
                />
                <PrivateRoute
                  exact
                  path="/account/settings"
                  component={SettingsPage}
                />
                <PrivateRoute
                  exact
                  path="/forms/:uuid/:tab/:id?"
                  component={FormBuilder}
                />
                <Route exact path="/form-submit/:uuid" component={FormSubmit} />
                <Route
                  exact
                  path="/email-verification"
                  component={EmailVerificationPage}
                />
                <Route
                  exact
                  path="/form-submit/iframe/:uuid"
                  component={FormSubmitIframe}
                />
                <PrivateRoute
                  exact
                  path="/forms-media"
                  component={DownloadMedia}
                />
                <Route component={PageNotFound} />
              </Switch>
            </div>
          </Content>
        </Layout>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(Routes);
