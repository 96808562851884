import React, { useState, useEffect } from "react";

import axios from "axios";
import {
  Button,
  Col,
  Divider,
  Layout,
  message,
  Result,
  Row,
  Tooltip,
} from "antd";

import { Link, useParams } from "react-router-dom";

import { SyncOutlined, DownloadOutlined } from "@ant-design/icons";

import AntSpinner from "../../../shared/customComponents/spinner/AntSpinner";
import FormSubmissionLists from "../../components/forms/formSubmissionLists/FormSubmissionLists";
import FormIndividualSubmission from "./FormIndividualSubmission";

const FormSubmissions = () => {
  const params = useParams<any>();

  console.log("all aprams in form submissions --->", params);

  const { uuid, id: submissionId } = params;

  const [loading, setLoading] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [formSubmissionData, setFormSubmisionData] = useState<any>();

  const [currentPageNo, setCurrentPageNo] = useState<number>(1);

  const getAllFormSubmissionsByUuid = async (setLoading: any) => {
    setLoading(true);

    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/${uuid}/saved-data/?size=1000`
      )
      .then(async (result1) => {
        setFormSubmisionData(result1.data.data);
        if (submissionId && result1.data.data.length > 0) {
          const modalDataToSet = result1.data.data.filter(
            (data: any) => data.id == submissionId
          );

          console.log("--------> modal data ---> ", modalDataToSet);

          if (modalDataToSet.length > 0) {
            handleClickViewResponse(modalDataToSet[0]);
          }
        }
        setLoading(false);
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Could not get form response data.");
        setLoading(false);
      });
  };

  const requestDownloadCsv = async (setLoading: any) => {
    setLoading(true);

    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/${uuid}/saved-data/export/`
      )
      .then(async (result1) => {
        message.info("CSV will be sent to your email.");
        setLoading(false);
      })
      .catch((err: any) => {
        message.error("Could not send data.");
        setLoading(false);
      });
  };

  const handleClickViewResponse = async (modalData: any) => {
    await setModalData(modalData);
    await setIsModalOpen(true);
  };

  useEffect(() => {
    getAllFormSubmissionsByUuid(setLoading);
  }, []);

  if (loading) {
    return <AntSpinner />;
  }

  return (
    <>
      <Layout>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Row
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Col xs={24} sm={24} md={24} xl={20} xxl={20}>
              <div
                style={{
                  width: "100%",
                }}
              >
                {!isModalOpen &&
                  formSubmissionData &&
                  formSubmissionData.length > 0 && (
                    <>
                      <Divider />
                      <Tooltip title="Download CSV">
                        <Button
                          type="primary"
                          onClick={() => requestDownloadCsv(setLoading)}
                        >
                          <DownloadOutlined />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Refresh Responses">
                        <Button
                          type="primary"
                          onClick={() => {
                            getAllFormSubmissionsByUuid(setLoading);
                          }}
                          style={{ marginLeft: "5px", marginTop: "5px" }}
                        >
                          <SyncOutlined />
                        </Button>
                      </Tooltip>

                      <FormSubmissionLists
                        formSubmissionData={formSubmissionData}
                        loading={loading}
                        handleClickViewResponse={handleClickViewResponse}
                        currentPageNo={currentPageNo}
                        setCurrentPageNo={setCurrentPageNo}
                      />
                    </>
                  )}

                {isModalOpen &&
                  formSubmissionData &&
                  formSubmissionData.length > 0 && (
                    <FormIndividualSubmission
                      modalData={modalData}
                      setIsModalOpen={setIsModalOpen}
                    />
                  )}

                {!isModalOpen &&
                  formSubmissionData &&
                  formSubmissionData.length <= 0 && (
                    <Result
                      title=" Your form has recieved no submissions."
                      extra={
                        <>
                          <Link to="/forms/all">
                            <Button type="primary" key="console">
                              Go to all forms
                            </Button>
                          </Link>
                          <Button
                            type="primary"
                            onClick={() => {
                              getAllFormSubmissionsByUuid(setLoading);
                            }}
                          >
                            <SyncOutlined /> Refresh Responses
                          </Button>
                        </>
                      }
                    />
                  )}
              </div>
            </Col>
          </Row>
        </div>
      </Layout>
    </>
  );
};

export default FormSubmissions;
