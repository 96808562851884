import React, { useEffect, useMemo, useState } from "react";

import { Button, Layout, Tabs, message } from "antd";
import { EyeOutlined, CopyOutlined } from "@ant-design/icons";

import { Link, useParams } from "react-router-dom";

import CTFormBuilder from "../../../formBuilder/components/CTFormBuilder";

import FormSettings from "./FormSettings";
import CopyToClipboard from "../../components/copyToClipboard/CopyToClipboard";
import FormResponses from "./FormResponses";
import { StateType } from "../../core/redux/types";
import { connect } from "react-redux";
import TemplateSettings from "../adminSettings/TemplateSettings";
import BreadcrumbNavigator from "./BreadcrumbNavigator";
import axios from "axios";

const { TabPane } = Tabs;

const MainForm = (props: any) => {
  const {
    setFormData,
    formData,
    handleElementCreate,
    updatedFormData,
    onElementOrderChange,
    handleElementDelete,
    onFormDetailsUpdate,
    activeTabKey,
  } = props;

  useEffect(() => { }, [formData]);

  const [activeKey, setActiveKey] = useState<string>("1");

  useEffect(() => {
    if (activeKey) {
      setActiveKey(activeTabKey);
    }
  }, [activeTabKey]);

  const handleElementUpdate = (prop: any) => {
    props.onElementUpdate(prop);
  };

  const handleElementPositionUpdate = (props: any) => {
    console.log("---- update position in app ----", props);
    onElementOrderChange(props);
  };

  let { uuid } = useParams<any>();

  interface IOperationsSlot {
    left: React.ReactNode;
    right: React.ReactNode;
  }

  const handleCreateDuplicateTemplate = async () => {

    const url = `${process.env.REACT_APP_SERVER_URL}/api/templates/${uuid}/clone/`

    await axios
      .post(url)
      .then((res) => {
        const duplicatedUuid = res.data.uuid;
        message.success("Template duplicated successfully. Redirecting to the new template...");
        window.location.href = `/forms/${duplicatedUuid}/builder`;
      })
      .catch((err: any) => {
        console.log("errors could not be get", err.response.data);
        if (err?.response?.data?.errors?.quota) {
          message.error(err.response.data.message);
        } else {
          message.error("Form could not be created.");
        }
      });

  };

  const handleCreateDuplicateForm = async () => {

    const url = `${process.env.REACT_APP_SERVER_URL}/api/${uuid}/clone/`

    await axios
      .post(url)
      .then((res) => {
        const duplicatedUuid = res.data.uuid;
        message.success("Form duplicated successfully. Redirecting to the new form...");
        window.location.href = `/forms/${duplicatedUuid}/builder`;
      })
      .catch((err: any) => {
        console.log("errors could not be get", err.response.data);
        if (err?.response?.data?.errors?.quota) {
          message.error(err.response.data.message);
        } else {
          message.error("Form could not be created.");
        }
      });

  };

  const OperationsSlot: IOperationsSlot = {
    left: <></>,
    right: (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {
          props.state.auth.userData.is_staff ? (

            <Button className="ct_forms_secondary_button" style={{ marginRight: 10 }} onClick={handleCreateDuplicateTemplate}>
              Duplicate
            </Button>
          ) : (
            <Button className="ct_forms_secondary_button" style={{ marginRight: 10 }} onClick={handleCreateDuplicateForm}>
              <CopyOutlined /> Copy Form
            </Button>
          )
        }
        <a
          href={`${process.env.REACT_APP_FRONTEND_URL}/form-submit/${uuid}?mode=preview`}
          target="_blank"
          rel="noreferrer"
          style={{ marginRight: "10px" }}
        >
          <Button className="ct_forms_secondary_button">
            <EyeOutlined />
            Preview
          </Button>
        </a>
        <CopyToClipboard
          linkForSubmission={`${process.env.REACT_APP_FRONTEND_URL}/form-submit/${uuid}`}
          linkForIframe={`${process.env.REACT_APP_FRONTEND_URL}/form-submit/iframe/${uuid}`}
          formUuid={uuid}
        />
      </div>
    ),
  };

  const [position, setPosition] = useState<any>(["left", "right"]);

  const slot: any = useMemo(() => {
    if (position.length === 0) return null;

    return position.reduce(
      (acc: any, direction: keyof IOperationsSlot) => ({
        ...acc,
        [direction]: OperationsSlot[direction],
      }),
      {}
    );
  }, [position]);

  const TabData = (
    <>
      <TabPane tab="Build" key="1">
        <Layout
          style={{
            maxWidth: "1500px",
            left: "0px",
            right: "0px",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
            backgroundColor: "red",
          }}
        >
          <CTFormBuilder
            getFormData={setFormData}
            maxElements={50}
            formData={formData}
            onElementCreate={handleElementCreate}
            onElementUpdate={handleElementUpdate}
            onElementDelete={handleElementDelete}
            handleImageDelete={props.handleImageDelete}
            handleImageUpload={props.handleImageUpload}
            updatedFormData={updatedFormData}
            onElementPositionUpdate={(props: any) =>
              handleElementPositionUpdate(props)
            }
            onFormDetailsUpdate={(props: any) => onFormDetailsUpdate(props)}
            debounce={1}
          />
        </Layout>
      </TabPane>
      <TabPane tab="Settings" key="2">
        {props.state.auth.userData.is_staff ? (
          <TemplateSettings />
        ) : (
          <FormSettings />
        )}
      </TabPane>
      {props.state.auth.userData.is_staff === false && (
        <TabPane tab="Responses" key="3">
          <FormResponses />
        </TabPane>
      )}
    </>
  );

  const TabsForDesktop = (
    <>
      <Tabs
        tabBarStyle={{ paddingLeft: 200 }}
        moreIcon
        centered
        tabBarExtraContent={slot}
        style={{ margin: "10px" }}
        activeKey={activeKey}
        onChange={(e) => {
          console.log("value of tab ", e);
          setActiveKey(e);
        }}
      >
        {TabData}
      </Tabs>
    </>
  );

  const TabsForMobile = (
    <div
      style={{
        paddingTop: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Link
          to="/forms/all"
          style={{
            marginLeft: "10px",
          }}
        ></Link>
        {
          props.state.auth.userData.is_staff ? (
            <Button className="ct_forms_secondary_button" style={{ marginRight: 10 }} onClick={handleCreateDuplicateTemplate}>
              Duplicate
            </Button>
          ) : (
            <Button className="ct_forms_secondary_button" style={{ marginRight: 10 }} onClick={handleCreateDuplicateForm}>
              <CopyOutlined /> Copy Form
            </Button>
          )
        }
        <a
          href={`${process.env.REACT_APP_FRONTEND_URL}/form-submit/${uuid}?mode=preview`}
          target="_blank"
          rel="noreferrer"
          style={{ marginLeft: "10px", marginRight: "10px" }}
        >
          <Button className="ct_forms_secondary_button">
            <EyeOutlined />
          </Button>
        </a>
        <CopyToClipboard
          linkForSubmission={`${process.env.REACT_APP_FRONTEND_URL}/form-submit/${uuid}`}
          linkForIframe={`${process.env.REACT_APP_FRONTEND_URL}/form-submit/iframe/${uuid}`}
          formUuid={uuid}
        />
      </div>
      <Tabs
        moreIcon
        centered
        activeKey={activeKey}
        onChange={(e) => {
          setActiveKey(e);
        }}
        style={{ margin: "10px" }}
      >
        {TabData}
      </Tabs>
    </div>
  );

  const { innerWidth: width } = window;

  return (
    <>
      <BreadcrumbNavigator />
      {width && width >= 1024 ? TabsForDesktop : TabsForMobile}
    </>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(MainForm);
