import React from "react";

import { Tabs } from "antd";

import UploadedFiles from "../drive/UploadedFiles";
import Responses from "../formResponses/Responses";
import FormSubmissions from "./FormSubmissions";

const { TabPane } = Tabs;

const FormResponses = () => {
  const TabData = (
    <>
      <TabPane tab="Responses" key="1">
        {window.innerWidth >= 500 ? <Responses /> : <FormSubmissions />}
      </TabPane>
      <TabPane tab="Drive" key="2">
        <UploadedFiles />
      </TabPane>
    </>
  );

  return (
    <div>
      {" "}
      <Tabs moreIcon centered type="card" style={{ margin: "10px" }}>
        {TabData}
      </Tabs>
    </div>
  );
};

export default FormResponses;
