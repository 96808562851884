import React, { useState, useEffect } from "react";

import axios from "axios";
import { Button, Col, Layout, message, Result, Row, Tooltip } from "antd";

import { Link, useParams } from "react-router-dom";

import { SyncOutlined, DownloadOutlined } from "@ant-design/icons";

import AntSpinner from "../../../shared/customComponents/spinner/AntSpinner";
import SingleResponse from "./SingleResponse";
import ResponsesList from "./ResponsesList";

const Responses = () => {
  const params = useParams<any>();

  const { uuid, id: submissionId } = params;

  const [loading, setLoading] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState<any>(null);

  const [formSubmissionData, setFormSubmisionData] = useState<any>();

  const [currentPageNo, setCurrentPageNo] = useState<number>(1);

  const [currentIndex, setCurrentIndex] = useState<number | undefined>();

  const [submissionsCount, setSubmissionsCount] = useState<number>(0);

  const getAllFormSubmissionsByUuid = async (setLoading: any) => {
    setLoading(true);

    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/${uuid}/saved-data/?size=1000`
      )
      .then(async (result1) => {
        setFormSubmisionData(result1.data.data);
        if (submissionId && result1.data.data.length > 0) {
          const modalDataToSet = result1.data.data.filter(
            (data: any) => data.id == submissionId
          );

          if (modalDataToSet.length > 0) {
            handleClickViewResponse(modalDataToSet[0], 0);
          }
        } else if (result1.data.data.length > 0) {
          handleClickViewResponse(result1.data.data[0], 0);
        }

        setSubmissionsCount(result1.data.data.length);

        setLoading(false);
      })
      .catch((err: any) => {
        console.log("errors could not be get", err);
        message.error("Could not get form response data.");
        setLoading(false);
      });
  };

  const requestDownloadCsv = async (setLoading: any) => {
    setLoading(true);

    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/${uuid}/saved-data/export/`
      )
      .then(async (result1) => {
        message.info("CSV will be sent to your email.");
        setLoading(false);
      })
      .catch((err: any) => {
        message.error("Could not send data.");
        setLoading(false);
      });
  };

  const handleClickViewResponse = async (modalData: any, newIndex: number) => {
    await setCurrentIndex(newIndex);
    await setModalData(modalData);
    await setIsModalOpen(true);
  };

  const changeCurrentIndex = async (index: number) => {
    console.log("new page number ---> ", index, Math.ceil((index + 1) / 10));

    const newPageNumber = Math.ceil(index / 10);
    if (index === 0) {
      setCurrentIndex(index);
      await setCurrentPageNo(1);
      await setModalData(formSubmissionData[index]);
      await setIsModalOpen(true);
    }
    if (newPageNumber !== 0) {
      await setCurrentPageNo(await newPageNumber);
      setCurrentIndex(index);
      await setModalData(formSubmissionData[index]);
      await setIsModalOpen(true);
    }
  };

  useEffect(() => {
    getAllFormSubmissionsByUuid(setLoading);
  }, []);

  if (loading) {
    return <AntSpinner />;
  }

  return (
    <>
      <Layout>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Row
            gutter={24}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <Col
              xs={24}
              sm={24}
              md={8}
              xl={7}
              xxl={6}
              style={{
                // width: "100%",
                display: "flex",
                flexDirection: "row",
                height: "100%",
                // justifyContent: "space-evenly",
                // alignItems: "center",
              }}
            >
              {formSubmissionData && formSubmissionData.length > 0 && (
                <div>
                  <Tooltip title="Download CSV">
                    <Button
                      type="primary"
                      onClick={() => requestDownloadCsv(setLoading)}
                    >
                      <DownloadOutlined />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Refresh Responses">
                    <Button
                      type="primary"
                      onClick={() => {
                        getAllFormSubmissionsByUuid(setLoading);
                      }}
                      style={{ marginLeft: "5px" }}
                    >
                      <SyncOutlined />
                    </Button>
                  </Tooltip>

                  <ResponsesList
                    formSubmissionData={formSubmissionData}
                    loading={loading}
                    handleClickViewResponse={handleClickViewResponse}
                    currentPageNo={currentPageNo}
                    setCurrentPageNo={setCurrentPageNo}
                    currentIndex={currentIndex}
                    selectedId={modalData ? modalData.id : 0}
                  />
                </div>
              )}
            </Col>

            <Col
              xs={24}
              sm={24}
              md={14}
              xl={17}
              xxl={18}
              style={{ height: "100%" }}
            >
              <div style={{ height: "100%" }}>
                {isModalOpen &&
                  formSubmissionData &&
                  formSubmissionData.length > 0 && (
                    <SingleResponse
                      modalData={modalData}
                      setIsModalOpen={setIsModalOpen}
                      currentIndex={currentIndex}
                      setCurrentIndex={setCurrentIndex}
                      changeCurrentIndex={changeCurrentIndex}
                      submissionsCount={submissionsCount}
                    />
                  )}
              </div>
            </Col>
          </Row>

          <Row
            gutter={24}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col
              xs={24}
              sm={24}
              md={24}
              xl={24}
              xxl={6}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {!isModalOpen &&
                formSubmissionData &&
                formSubmissionData.length <= 0 && (
                  <div
                    style={{
                      width: "100%",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Result
                      title=" Your form has recieved no submissions."
                      extra={
                        <>
                          <Link to="/forms/all">
                            <Button
                              type="primary"
                              key="console"
                              style={{ margin: "10px" }}
                            >
                              Go to all forms
                            </Button>
                          </Link>
                          <Button
                            type="primary"
                            onClick={() => {
                              getAllFormSubmissionsByUuid(setLoading);
                            }}
                          >
                            <SyncOutlined /> Refresh Responses
                          </Button>
                        </>
                      }
                    />
                  </div>
                )}
            </Col>
          </Row>
        </div>
      </Layout>
    </>
  );
};

export default Responses;
