import React, { useEffect, useState } from "react";

import { Table } from "antd";

const ResponseMetadata = (props: any) => {
  const [dataSource, setDataSource] = useState<any>();

  useEffect(() => {
    // const noMeta = null;
    console.log("******* props meta is present ", props);
    if (props.metadata) {
      const data = {
        ip: props.metadata.ip,
        browser_info: props.metadata.browser_info,
        city: props.metadata.city,
        country: props.metadata.country,
        device_info: props.metadata.device_info,
        created: props.submissionData.created,
      };
      setDataSource([data]);
    } else {
      console.log("******* props meta is not present ", props);
      const data = {
        ip: "-",
        browser_info: "-",
        city: "-",
        country: "-",
        device_info: "-",
        created: "-",
      };
      setDataSource([data]);
    }
  }, [props]);

  const columns = [
    {
      title: "Visitor Details",
      dataIndex: "ip",
      key: "ip",
      render: (ip: any, record: any) => (
        <p>
          {ip} {console.log("record visitor digitalk ---->", record)}
        </p>
      ),
    },
    {
      title: "Date",
      dataIndex: "created",
      key: "created",
      render: (created: any) => (
        <>
          {created !== "-" && (
            <p>
              {" "}
              {new Date(created).toLocaleDateString("en-gb", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
              {", "}
              {
                Intl.DateTimeFormat("en", {
                  hour: "numeric",
                  minute: "numeric",
                }).format(new Date(created)) // 2 PM
              }
            </p>
          )}
          {created == "-" && <p>-</p>}
        </>
      ),
    },
    {
      title: "Browser",
      dataIndex: "browser_info",
      key: "browser_info",
      render: (browser_info: any, record: any) => <p>{browser_info}</p>,
    },
    {
      title: "Location",
      dataIndex: "city",
      key: "city",
      render: (city: string, record: any) => (
        <>
          {" "}
          {city !== "-" && (
            <p>
              {city}, {record.country}
            </p>
          )}
          {city == "-" && <p>-</p>}
        </>
      ),
    },
    {
      title: "Device",
      dataIndex: "device_info",
      key: "device_info",
      render: (device_info: string) => (
        <p>{device_info === "undefined undefined" ? "-" : device_info}</p>
      ),
    },
  ];

  if (!dataSource) {
    return <p>No analytics data to show for this form</p>;
  }

  return (
    <Table
      showHeader={true}
      tableLayout="auto"
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      size="middle"
    />
  );
};

export default ResponseMetadata;
